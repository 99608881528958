export default function Footer() {
  return (
    <footer id="contact" className="footer py-4">
      <div className="container text-center">
        <h2>Contact Me</h2>
        <p>Email: info@FundoraAcademy.com</p>
        <p>Phone: (480) 738-6281</p>
        <p>
          Follow me on:
          <a href="#" className="ms-2">
            Facebook
          </a>{" "}
          |
          <a href="#" className="ms-2">
            Twitter
          </a>{" "}
          |
          <a href="#" className="ms-2">
            Instagram
          </a>
        </p>
      </div>
    </footer>
  );
}
