import ProgramCard from "./ProgramCard";import programData from "../hooks/programData.json";export default function Programs(){return(<section id="programs" className="programs py-5"><div className="container"><h2 className="text-center mb-5">Programs</h2><div className="row">{programData.map((program,index)=>(<ProgramCard
  key={index}
  title={program.title}
  text={program.text}
  icon={program.icon}/>))}</div>{}<div className="row mt-5"><div className="col-12"><div className="video-wrapper"><div className="embed-responsive embed-responsive-16by9"><iframe
  className="embed-responsive-item"
  src="https://www.youtube.com/embed/XLuLRZAt5ic?si=J48Lnl5BSx7zxdAR"
  title="YouTube video player"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
  referrerPolicy="strict-origin-when-cross-origin"
  allowFullScreen></iframe></div></div></div></div></div></section>)}