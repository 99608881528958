import axios from "axios";import{useState}from "react";import{Button,Form,Container,Row,Col}from "react-bootstrap";const sendEmailIP=`${process.env.REACT_APP_Backen_URL}/api/send-email`;const ContactUs=()=>{const[formData,setFormData]=useState({name:"",email:"",message:"",});const[status,setStatus]=useState("");const handleChange=(e)=>{const{name,value}=e.target;setFormData({...formData,[name]:value})};const handleSubmit=async(e)=>{e.preventDefault();setStatus("Sending...");try{await axios.post(sendEmailIP,formData);setStatus("Message sent successfully!");setFormData({name:"",email:"",message:""})}catch(error){console.error("Email send error:",error);setStatus("Failed to send message. Please try again later.")}};return(<Container className="contact-us-container"><div className="contact-info-section"><Row className="contact-info mb-4"><Col xs={12}md={4}className="contact-detail"><h5>Phone/Text</h5><p>(480) 738-6281</p></Col><Col xs={12}md={4}className="contact-detail"><h5>Email</h5><p>info@FundoraAcademy.com</p></Col><Col xs={12}md={4}className="contact-detail"><h5>WeChat</h5><img
src={`${process.env.PUBLIC_URL}/images/barcode.png`}
alt="WeChat QR Code"
className="wechat-qr"
style={{height:'150px'}}/></Col></Row>{}<Form className="contact-form" onSubmit={handleSubmit}><h4>Or fill out the form below:</h4><Form.Group controlId="formName" className="mb-3"><Form.Label>Name</Form.Label><Form.Control
type="text"
placeholder="Enter your name"
name="name"
value={formData.name}
onChange={handleChange}
required/></Form.Group><Form.Group controlId="formEmail" className="mb-3"><Form.Label>Email</Form.Label><Form.Control
type="email"
placeholder="Enter your email"
name="email"
value={formData.email}
onChange={handleChange}
required/></Form.Group><Form.Group controlId="formMessage" className="mb-3"><Form.Label>Message</Form.Label><Form.Control
as="textarea"
rows={3}
placeholder="Your message"
name="message"
value={formData.message}
onChange={handleChange}
required/></Form.Group><Button variant="primary" type="submit">Submit</Button></Form>{status&&<p className="mt-3">{status}</p>}</div></Container>)};export default ContactUs