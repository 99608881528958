import BlogPageTitle from "./BlogPageTitle";import Article from "./Article_v2";import WidgetSearch from "./WidgetSearch";import WidgetCategory from "./WidgetCategory";import WidgetPost from "./WidgetPost";import WidgetTag from "./WidgetTag";export default function BlogDetail({blogPosts,loading,error,searchQuery,handleSearch,resetSearchQuery,selectedCategory,selectedTag,currentPage,displayPosts,totalPosts,handlePageChange,categories,uniqueTags,recentPosts,resetToFirstPage,postsPerPage,}){if(loading)return<p>Loading blog posts...</p>;if(error)return<p>Error loading blog posts.</p>;return(<main className="main"><BlogPageTitle
  selectedCategory={selectedCategory}
  selectedTag={selectedTag}
  searchQuery={searchQuery}
  resetToFirstPage={resetToFirstPage}
  resetSearchQuery={resetSearchQuery}/><div className="container"><div className="row"><div className="col-lg-8"><Article blogPosts={blogPosts}loading={loading}error={error}/></div><div className="col-lg-4 sidebar"><div className="widgets-container"><WidgetSearch onSearch={handleSearch}/><WidgetCategory
  categories={categories}
  resetToFirstPage={resetToFirstPage}
  resetSearchQuery={resetSearchQuery}/><WidgetPost posts={recentPosts}/><WidgetTag
  tags={uniqueTags}
  resetToFirstPage={resetToFirstPage}
  resetSearchQuery={resetSearchQuery}/></div></div></div></div></main>)}