export default function BlogPagination({totalPosts,postsPerPage,currentPage,onPageChange,}){const totalPages=Math.ceil(totalPosts/postsPerPage);const pageNumbers=[];for(let i=1;i<=totalPages;i++){pageNumbers.push(i)}
const handlePageChange=(pageNumber)=>{if(pageNumber>=1&&pageNumber<=totalPages){onPageChange(pageNumber)}};return(<section id="blog-pagination" className="blog-pagination section py-5"><div className="container"><div className="d-flex justify-content-center"><ul className="pagination">{}<li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}><button
className="page-link"
onClick={()=>handlePageChange(currentPage-1)}
aria-label="Previous Page"
disabled={currentPage===1}><i className="bi bi-chevron-left"/></button></li>{}
{pageNumbers.map((number)=>(<li
key={number}
className={`page-item ${
                  number === currentPage ? "active" : ""
                }`}><button
className="page-link"
onClick={()=>handlePageChange(number)}
aria-label={`Page ${number}`}>{number}</button></li>))}
{}<li
className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}><button
className="page-link"
onClick={()=>handlePageChange(currentPage+1)}
aria-label="Next Page"
disabled={currentPage===totalPages}><i className="bi bi-chevron-right"/></button></li></ul></div></div></section>)}