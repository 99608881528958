import{Link,useNavigate}from "react-router-dom";import useHeaderUtils from "../hooks/useHeaderUtils";import{googleLogout}from "@react-oauth/google";import{useEffect,useState}from "react";import axios from "axios";import{Modal}from "react-bootstrap";import ContactUs from "./ContactUs";const logoutIP=`${process.env.REACT_APP_Backen_URL}/api/logout`;export default function Header({resetSearchQuery,isAuthenticated,setIsAuthenticated,}){const navigate=useNavigate();const{handleNavLinkClick,handleNavItemClick,initializeOutsideClickHandler,navbarRef,isMobile,}=useHeaderUtils();const handleSignOut=()=>{axios.post(logoutIP,{},{withCredentials:!0}).then(()=>{setIsAuthenticated(!1);googleLogout();navigate("/")}).catch((error)=>{})};useEffect(()=>{const removeOutsideClickHandler=initializeOutsideClickHandler();return()=>{removeOutsideClickHandler()}},[initializeOutsideClickHandler,isMobile]);const[showContactModal,setShowContactModal]=useState(!1);const handleShowContactModal=(e)=>{e.preventDefault();setShowContactModal(!0)};const handleCloseContactModal=()=>setShowContactModal(!1);return(<header className="header"><nav
  ref={navbarRef}
  className="navbar navbar-expand-lg fixed-top colorful-navbar">{}<div className="container"><a className="navbar-brand" href={"/"}><img
  src={`${process.env.PUBLIC_URL}/images/pencile_icon.svg`}
  className="navbar-logo"
  style={{marginRight:"10px"}}/>{}
  Fundora Academy</a><button
  className="navbar-toggler"
  type="button"
  data-bs-toggle="collapse"
  data-bs-target="#navbarNav"
  aria-controls="navbarNav"
  aria-expanded="false"
  aria-label="Toggle navigation"><span className="navbar-toggler-icon colorful-toggler"></span></button><div className="collapse navbar-collapse" id="navbarNav"><ul className="navbar-nav ms-auto"><li className="nav-item"><Link
  className="nav-link colorful-link"
  to="/"
  onClick={(e)=>handleNavLinkClick(e,"home")}>Home</Link></li><li className="nav-item"><Link
  className="nav-link colorful-link"
  to="/"
  onClick={(e)=>handleNavLinkClick(e,"about")}>About</Link></li><li className="nav-item"><Link
  className="nav-link colorful-link"
  to="/"
  onClick={(e)=>handleNavLinkClick(e,"programs")}>Programs</Link></li><li className="nav-item"><Link
  className="nav-link colorful-link"
  to="/blog"
  onClick={()=>{resetSearchQuery(),handleNavItemClick()}}>Blog</Link></li><li className="nav-item"><Link
  className="nav-link colorful-link"
  to={isAuthenticated?"/resources":"/signup"}
  onClick={handleNavItemClick}>Resources</Link></li><li className="nav-item"><Link
  className="nav-link colorful-link"
  to="/"
  onClick={handleShowContactModal}>Contact</Link>{}<Modal
  show={showContactModal}
  onHide={handleCloseContactModal}
  size="lg"
  centered><Modal.Header closeButton><Modal.Title>Contact Us</Modal.Title></Modal.Header><Modal.Body>{}<ContactUs/></Modal.Body></Modal></li><li className="nav-item">{}
  {isAuthenticated?(<button
  className="btn custom-btn btn-logout nav-link me-2"
  onClick={()=>{handleSignOut(),handleNavItemClick()}}>Sign Out</button>):(<Link
  className="btn custom-btn btn-login nav-link me-2"
  to="/signup"
  onClick={handleNavItemClick}>Sign In</Link>)}</li></ul></div></div></nav></header>)}