import React from "react";import{useEffect,useState}from "react";import{BrowserRouter as Router,Route,Routes,useNavigate,Navigate,}from "react-router-dom";import{BlogProvider,useBlog}from "./hooks/blogContext";import{GoogleOAuthProvider}from "@react-oauth/google";import axios from "axios";import Header from "./component/Header_v3";import Hero from "./component/Hero_v2";import About from "./component/About";import Programs from "./component/Programs";import BlogSection from "./component/BlogSection";import BlogDetail from "./component/BlogDetail_v2";import BlogPage from "./component/BlogPage";import Resources from "./component/Resources";import Footer from "./component/Footer";import ScrollToTop from "./hooks/scrollToTop";import useBlogUtils from "./hooks/useBlogUtils";import Projects from "./resources/Projects_4";import Tutorials from "./resources/Tutorials";import Challenges from "./resources/Challenges";import GameAssets from "./resources/GameAssets";import CodingTools from "./resources/CodingTools"
import LearningResources from "./resources/LearningResources";import GoogleSignUp from "./component/GoogleSignUp_v2";const Google_Client_ID=process.env.REACT_APP_Google_ClientID;const authIP=`${process.env.REACT_APP_Backen_URL}/api/authenticate`;import "./styles_color.css";import "./styles_all.css";function AppContent(){const{blogPosts,loading,error}=useBlog();const[isAuthenticated,setIsAuthenticated]=React.useState(!1);const{categories,uniqueTags,recentPosts,displayPosts,totalPosts,selectedCategory,selectedTag,searchQuery,handleSearch,resetSearchQuery,handlePageChange,resetToFirstPage,currentPage,postsPerPage,}=useBlogUtils(blogPosts,loading,error);const[authLoading,setLoading]=useState(!0);useEffect(()=>{axios.get(authIP,{withCredentials:!0}).then((response)=>{if(response.data.isAuthenticated){setIsAuthenticated(!0)}}).catch((error)=>{}).finally(()=>{setLoading(!1)})},[]);if(authLoading){return<div>loading</div>}
return(<div className="App"><ScrollToTop/><Header
resetSearchQuery={resetSearchQuery}
isAuthenticated={isAuthenticated}
setIsAuthenticated={setIsAuthenticated}/><Routes><Route
path="/"
element={<><Hero/><div className="body"><About/><Programs/><BlogSection
blogPosts={blogPosts}
loading={loading}
error={error}/></div></>}/><Route
path="/resources"
element={isAuthenticated?<Resources/>:<Navigate to="/signup"/>}/><Route
path="/signup"
element={<GoogleSignUp onLoginSuccess={()=>setIsAuthenticated(!0)}/>}/><Route
path="/blog"
element={<BlogPage
blogPosts={blogPosts}
loading={loading}
error={error}
searchQuery={searchQuery}
handleSearch={handleSearch}
resetSearchQuery={resetSearchQuery}
selectedCategory={selectedCategory}
selectedTag={selectedTag}
currentPage={currentPage}
displayPosts={displayPosts}
totalPosts={totalPosts}
handlePageChange={handlePageChange}
categories={categories}
uniqueTags={uniqueTags}
recentPosts={recentPosts}
resetToFirstPage={resetToFirstPage}
postsPerPage={postsPerPage}/>}/><Route
path="/blog/:id"
element={<BlogDetail
blogPosts={blogPosts}
loading={loading}
error={error}
searchQuery={searchQuery}
handleSearch={handleSearch}
resetSearchQuery={resetSearchQuery}
selectedCategory={selectedCategory}
selectedTag={selectedTag}
currentPage={currentPage}
displayPosts={displayPosts}
totalPosts={totalPosts}
handlePageChange={handlePageChange}
categories={categories}
uniqueTags={uniqueTags}
recentPosts={recentPosts}
resetToFirstPage={resetToFirstPage}
postsPerPage={postsPerPage}/>}/><Route
path="/resources/projects"
element={isAuthenticated?<Projects/>:<Navigate to="/signup"/>}/><Route
path="/resources/tutorials"
element={isAuthenticated?<Tutorials/>:<Navigate to="/signup"/>}/><Route
path="/resources/assets"
element={isAuthenticated?<GameAssets/>:<Navigate to="/signup"/>}/><Route
path="/resources/tools"
element={isAuthenticated?<CodingTools/>:<Navigate to="/signup"/>}/><Route
path="/resources/learning"
element={isAuthenticated?<LearningResources/>:<Navigate to="/signup"/>}/><Route
path="/resources/challenges"
element={isAuthenticated?<Challenges/>:<Navigate to="/signup"/>}/><Route path="*" element={<div>Page Not Found</div>}/></Routes><Footer/></div>)}
export default function App(){return(<GoogleOAuthProvider clientId={Google_Client_ID}><BlogProvider><Router><AppContent/></Router></BlogProvider></GoogleOAuthProvider>)}