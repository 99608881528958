export default function About(){return(<section id="about" className="about py-5"><div className="container"><h2 className="text-center mb-5">About Me</h2><div className="about-content d-flex flex-column flex-md-row align-items-center"><div className="about-text me-md-5"><p className="lead text-center text-md-start">
I am Dr. Bin Mu. I am a passionate educator with a PhD in
Engineering from Georgia Tech. With years of experience in
teaching programming, I strive to make coding an exciting and
accessible adventure for K-12 students. My mission is to ignite
their curiosity and equip them with critical thinking and
problem-solving skills, essential tools in our digital age. By
transforming young minds through the power of code, I aim to
empower the next generation of digital innovators. Together, in
our coding lessons, we build future-ready skills and have fun
along the way.</p></div><div className="about-image mt-4 mt-md-0"><img
src="/images/binmu1.png"
alt="My Picture"
className="profile-image"/></div></div></div></section>)}