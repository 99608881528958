import BlogPageTitle from "./BlogPageTitle";import ArticleCard from "./ArticleCard";import BlogPagination from "./BlogPagination";import WidgetSearch from "./WidgetSearch";import WidgetCategory from "./WidgetCategory";import WidgetPost from "./WidgetPost";import WidgetTag from "./WidgetTag";export default function BlogPage({blogPosts,loading,error,searchQuery,handleSearch,resetSearchQuery,selectedCategory,selectedTag,currentPage,displayPosts,totalPosts,handlePageChange,categories,uniqueTags,recentPosts,resetToFirstPage,postsPerPage,}){if(loading)return<p>Loading blog posts...</p>;if(error)return<p>Error loading blog posts.</p>;return(<main className="blogPage"><BlogPageTitle
  selectedCategory={selectedCategory}
  selectedTag={selectedTag}
  searchQuery={searchQuery}
  resetToFirstPage={resetToFirstPage}
  resetSearchQuery={resetSearchQuery}/><div className="container"><div className="row"><div className="col-lg-8">{}<section id="blog-posts" className="blog-posts section py-5"><div className="container"><div className="row gy-4">{displayPosts.length>0?(displayPosts.filter((post)=>post.fields.publish).map((post)=>(<div className="col-12" key={post.sys.id}><ArticleCard
  id={post.sys.id}
  title={post.fields.blogTitle}
  category={post.fields.blogCategory}
  author={post.fields.blogAuthor}
  image={post.fields.blogImage.fields.file.url}
  summary={post.fields.blogSummary}
  date={new Date(post.fields.publishDate).toLocaleDateString("en-GB",{day:"numeric",month:"long",year:"numeric",})}/></div>))):(<p>Hmm, nothing here. Maybe try another search?</p>)}</div></div></section><BlogPagination
  totalPosts={totalPosts}
  postsPerPage={postsPerPage}
  currentPage={currentPage}
  onPageChange={handlePageChange}/></div><div className="col-lg-4 sidebar"><div className="widgets-container"><WidgetSearch onSearch={handleSearch}/><WidgetCategory
  categories={categories}
  resetToFirstPage={resetToFirstPage}
  resetSearchQuery={resetSearchQuery}/><WidgetPost posts={recentPosts}/><WidgetTag
  tags={uniqueTags}
  resetToFirstPage={resetToFirstPage}
  resetSearchQuery={resetSearchQuery}/></div></div></div></div></main>)}