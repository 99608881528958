import React from "react";import{Link}from "react-router-dom";export default function BlogPageTitle({selectedCategory,selectedTag,searchQuery,resetToFirstPage,resetSearchQuery,}){if(selectedCategory||selectedTag){searchQuery=null}
return(<div className="page-title"><div className="heading"><div className="container"><div className="row d-flex justify-content-center text-center"><div className="col-lg-8"><h1>Blog</h1></div></div></div></div><nav className="breadcrumbs"><div className="container"><ol><li><Link to="/">Home</Link></li><li className="current"><Link
to="/blog"
onClick={()=>{resetToFirstPage();resetSearchQuery()}}>Blog</Link></li>{}
{selectedCategory&&!selectedTag&&(<li className="current"><Link
to={`/blog?category=${encodeURIComponent(selectedCategory)}`}
onClick={resetToFirstPage}>{selectedCategory}</Link></li>)}
{}
{selectedTag&&(<li className="current"><Link
to={`/blog?tag=${encodeURIComponent(selectedTag)}`}
onClick={resetToFirstPage}>{selectedTag.toLowerCase()}</Link></li>)}
{}
{searchQuery&&(<li className="current"><Link
to={`/blog?search=${encodeURIComponent(searchQuery)}`}
onClick={resetToFirstPage}>searching: {searchQuery.toLowerCase()}</Link></li>)}</ol></div></nav></div>)}