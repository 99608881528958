import{useState}from "react";import{Modal,Button}from "react-bootstrap";import ContactUs from "./ContactUs";export default function Hero(){const[showContactModal,setShowContactModal]=useState(!1);const handleShowContactModal=()=>setShowContactModal(!0);const handleCloseContactModal=()=>setShowContactModal(!1);return(<section
  id="home"
  className="hero"
  style={{backgroundImage:`url(${process.env.PUBLIC_URL + "/images/cover_1.webp"})`,}}><div className="hero-overlay"><div className="container text-center"><h1 className="display-4">K-12 Kids Coding & Programming Club</h1><p className="lead">Unlock your child's potential with coding! Fun, interactive, and
  educational classes for every skill level.</p>{}<Button
  className="btn btn-primary btn-lg mt-3"
  onClick={handleShowContactModal}>Join the Club</Button>{}<Modal
  show={showContactModal}
  onHide={handleCloseContactModal}
  size="lg"
  centered><Modal.Header closeButton><Modal.Title>Contact Us</Modal.Title></Modal.Header><Modal.Body>{}<ContactUs/></Modal.Body></Modal></div></div></section>)}